//React
import React from 'react'
import { connect } from 'react-redux'

//Actions
import { saveLead } from '../../actions/saveLead'

//Icons
import { FaSpinner } from 'react-icons/fa'

//Redux
const mapStateToProps = ({ leadsubmitted, campaignid, isLoading }) => {
  return { leadsubmitted, campaignid, isLoading }
}
const mapDispatchToProps = (dispatch) => ({
  saveLead: (userData) => {
    dispatch(saveLead(userData))
  },
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      userLastName: '',
      userTitle: '',
      userCompany: '',
      userEmail: '',
      userPhone: '',
      userEmployees: '',
      initialLoad: true,
      submitted: false,
      submitComplete: false,
      error: {
        userName: false,
        userLastName: false,
        userCompany: false,
        userEmail: false,
        userPhone: false,
        userEmployees: false,
      },
    }
  }

  componentDidMount() {
    if (this.props.leadSource) {
      console.log(`**leadsource ${this.props.leadSource}`)
      console.log('this.props.campaignid')
      console.log(this.props.campaignid)
      this.setState({
        leadSource: `${this.props.leadSource}${
          this.props.campaignid ? `-${this.props.campaignid}` : ''
        }`,
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      initialLoad: false,
    })
  }

  sendConversionEvent() {
    if (
      typeof window !== `undefined` &&
      process.env.STAGE !== 'dev' &&
      process.env.STAGE !== 'staging'
    ) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-615742954/GdUSCK7N9NYBEOr7zaUC',
      })
    }
  }

  handleValidation(event) {
    event.preventDefault()

    const formData = this.state

    console.log('**formData')
    console.log(formData)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    const validEmail = emailRegex.test(formData.userEmail)

    let errorObj = {
      userName: false,
      userLastName: false,
      userCompany: false,
      userEmail: false,
      userPhone: false,
      userEmployees: false,
    }
    if (!formData.userName) {
      // this.setError('userName')
      console.log('no name')
      errorObj.userName = true
    }
    if (!formData.userLastName) {
      // this.setError('userLastName')
      errorObj.userLastName = true

      // return
    }
    if (!formData.userCompany) {
      // this.setError('userCompany')
      errorObj.userCompany = true

      // return
    }
    if (!formData.userEmail || validEmail !== true) {
      // this.setError('userEmail')
      errorObj.userEmail = true

      // return
    }
    if (!formData.userPhone) {
      // this.setError('userPhone')
      errorObj.userPhone = true
      // return
    }
    if (!formData.userEmployees) {
      // this.setError('userEmployees')
      errorObj.userEmployees = true
      // return
    }
    console.log('errorObj')
    console.log(errorObj)
    console.log('this.state.error')
    console.log(this.state.error)

    this.setState({ error: errorObj })

    if (
      this.state.initialLoad === false &&
      Object.values(errorObj).every((value) => value === false)
    ) {
      console.log('**handline submit**')
      this.handleSubmit()
    }
  }
  handleSubmit = () => {
    const formData = this.state

    console.log('formData')
    console.log(formData)

    this.sendConversionEvent()
    this.props.saveLead(formData)
  }
  render() {
    const { isLoading, leadsubmitted } = this.props

    return (
      <>
        <div class="gradient-callout-algae">
          <div class="container p-4">
            <div class="row">
              <div class="col-lg-12 d-flex align-items-center justify-content-center text-center">
                <h1 class="display-2 font-weight-normal white-1">
                  {this.props.title
                    ? this.props.title
                    : 'Keep your business healthy'}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="container pb-5">
          <div class="row text-center">
            <div class="col-lg-8 offset-lg-2">
              {leadsubmitted === false ? (
                <div class="p-4">
                  <h2 class="display-4 my-3 p-2">
                    {this.props.subtitle
                      ? this.props.subtitle
                      : "We'd like to show you how mobile health certifications can reduce risk and inspire customer confidence. Request a demo below:"}
                  </h2>

                  <form>
                    <div class="form-group">
                      <label for="userName" class="d-none">
                        First name
                      </label>

                      <input
                        type="text"
                        class={`form-control form-control-lg ${
                          this.state.error.userName ? 'is-invalid' : ''
                        }`}
                        name="userName"
                        id="userName"
                        placeholder="First name"
                        value={this.state.userName}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <label for="userLastName" class="d-none">
                        Last name
                      </label>
                      <input
                        type="text"
                        class={`form-control form-control-lg ${
                          this.state.error.userLastName ? 'is-invalid' : ''
                        }`}
                        name="userLastName"
                        id="userLastName"
                        placeholder="Last name"
                        value={this.state.userLastName}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    {/* <div class="form-group">
                      <label for="userTitle" class="d-none">
                        Job title
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="userTitle"
                        id="userTitle"
                        placeholder="Job title"
                        value={this.state.userTitle}
                        onChange={this.handleInputChange}
                      />
                    </div> */}
                    <div class="form-group">
                      <label for="userCompany" class="d-none">
                        Organization name
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control form-control-lg ${
                          this.state.error.userCompany ? 'is-invalid' : ''
                        }`}
                        name="userCompany"
                        id="userCompany"
                        placeholder="Organization name"
                        value={this.state.userCompany}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <label for="userEmail" class="d-none">
                        Business email
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control  form-control-lg ${
                          this.state.error.userEmail ? 'is-invalid' : ''
                        }`}
                        name="userEmail"
                        id="userEmail"
                        placeholder="Business email"
                        value={this.state.userEmail}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <label for="userPhone" class="d-none">
                        Business phone{' '}
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control form-control-lg ${
                          this.state.error.userPhone ? 'is-invalid' : ''
                        }`}
                        name="userPhone"
                        id="userPhone"
                        placeholder="Business phone"
                        value={this.state.userPhone}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <label for="userEmployees" class="d-none">
                        Number of employees{' '}
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control form-control-lg ${
                          this.state.error.userEmployees ? 'is-invalid' : ''
                        }`}
                        name="userEmployees"
                        id="userEmployees"
                        placeholder="# of employees"
                        value={this.state.userEmployees}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    {/* <div class="form-group">
                      <label for="userComments" class="d-none">
                        Anything you'd like to tell us?{' '}
                      </label>
                      <textarea
                        class="form-control form-control-lg"
                        name="userComments"
                        id="userComments"
                        rows="3"
                        placeholder="Anything you'd like to tell us?"
                        onChange={this.handleInputChange}
                      />
                    </div> */}
                    <div class="py-3">
                      <button
                        type="submit"
                        class="btn btn-cta-reverse btn-lg btn-block"
                        onClick={(e) => this.handleValidation(e)}
                      >
                        {this.props.cta ? this.props.cta : 'Send'}
                        {isLoading ? (
                          <FaSpinner
                            size={15}
                            className="icon-spin"
                            style={{ marginLeft: '10px' }}
                          />
                        ) : null}
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div class="p-4">
                  <h2 class="display-3 my-3 p-2">
                    Thanks! We'll be in touch shortly with you.
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
